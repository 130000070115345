@use "common/reset";
@use "grid/grid";
@use "fonts/fonts";
@use "common/global";
@use "mixins/breakpoints";
@use "sass:map";
@use "variables/gutters";

:root {
  --myriad-pro-regular: 'Myriad Pro Regular', Adjusted Arial;
  --myriad-pro-bold: 'Myriad Pro Bold';
  --myriad-pro-regular-italic: 'Myriad Pro It';
  --myriad-pro-semibold: 'Myriad Pro Semibold', Adjusted Arial Semibold;
  --myriad-pro-light: 'Myriad Pro Light';
  --message-height: 3rem;
  --container-gutter: #{gutters.get-gutter(16)};
  --page-bottom-padding: #{gutters.get-gutter(96)};

  @include breakpoints.screen(md) {
    --header-height: #{var(--header-height-mobile)} !important;
    --message-height: 2rem;
    --page-bottom-padding: #{gutters.get-gutter(64)};
  }
}

html {
  font-family: var(--myriad-pro-regular), sans-serif;
  font-size: 10px;
  color: var(--primary);
  -moz-osx-font-smoothing: grayscale; /* Firefox */
  -webkit-font-smoothing: antialiased; /* WebKit */
  text-rendering: geometricPrecision;

  &.disable-scroll {
    overflow-y: scroll;
    position: fixed;
    width: 100%;
  }

}

body {
  &.disable-scroll {
    overflow-y: hidden;
  }
  &.disable-scroll-lg {
    @include breakpoints.screen(lg) {
      overflow-y: scroll;
      position: fixed;
      width: 100%;
    }
  }


  @mixin generate-breakpoint-hide-classes {
    @each $breakpoint, $value in grid.$grid-breakpoints {
      .hide-#{$breakpoint} {
        @media (max-width: $value - 1) {
          display: none !important;
        }
      }
    }
  }

  @mixin generate-breakpoint-show-classes {
    @each $breakpoint, $value in grid.$grid-breakpoints {
      .show-#{$breakpoint} {
        @media (min-width: $value) {
          display: none !important;
        }
      }
    }
  }

  @include generate-breakpoint-show-classes;
  @include generate-breakpoint-hide-classes;
}
