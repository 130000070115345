@use "sass:map";
@use "variables/breakpoints";
@use "variables/gutters";

$gutter: var(--container-gutter);
$gutter-half: calc($gutter / 2);
$grid-breakpoints: breakpoints.$breakpoints;
/********** Grid styles start **********/
.row {
  display: flex;
  flex-wrap: wrap;
  flex-grow: 1;
  margin-left: calc($gutter / 2 * -1);
  margin-right: calc($gutter / 2 * -1);
}

.col {
  position: relative;
  padding-right: $gutter-half;
  padding-left: $gutter-half;
  float: left;
  width: 100%;
}

.without-grid-paddings {
  .row {
    margin-left: 0;
    margin-right: 0;
  }

  .col {
    padding: 0;
  }
}

.col-xs-12 {
  width: 100%;
  max-width: 100%;
}

.col-xs-11 {
  width: 91.66666667%;
  max-width: 91.66666667%;
}

.col-xs-10 {
  width: 83.33333333%;
  max-width: 83.33333333%;
}

.col-xs-9 {
  width: 75%;
  max-width: 75%;
}

.col-xs-8 {
  width: 66.66666667%;
  max-width: 66.66666667%;
}

.col-xs-7 {
  width: 58.33333333%;
  max-width: 58.33333333%;
}

.col-xs-6 {
  width: 50%;
  max-width: 50%;
}

.col-xs-5 {
  width: 41.66666667%;
  max-width: 41.66666667%;
}

.col-xs-4 {
  width: 33.33333333%;
  max-width: 33.33333333%;
}

.col-xs-3 {
  width: 25%;
  max-width: 25%;
}

.col-xs-2 {
  width: 16.66666667%;
  max-width: 16.66666667%;
}

.col-xs-1 {
  width: 8.33333333%;
  max-width: 8.33333333%;
}

@media (min-width: #{map.get($grid-breakpoints, sm)}) {
  .col-sm-12 {
    width: 100%;
    max-width: 100%;
  }
  .col-sm-11 {
    width: 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-sm-10 {
    width: 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-sm-9 {
    width: 75%;
    max-width: 75%;
  }
  .col-sm-8 {
    width: 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-sm-7 {
    width: 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-sm-6 {
    width: 50%;
    max-width: 50%;
  }
  .col-sm-5 {
    width: 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-sm-4 {
    width: 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-sm-3 {
    width: 25%;
    max-width: 25%;
  }
  .col-sm-2 {
    width: 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-sm-1 {
    width: 8.33333333%;
    max-width: 8.33333333%;
  }
}

@media (min-width: #{map.get($grid-breakpoints, md)}) {
  .col-md-12 {
    width: 100%;
    max-width: 100%;
  }
  .col-md-11 {
    width: 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-md-10 {
    width: 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-md-9 {
    width: 75%;
    max-width: 75%;
  }
  .col-md-8 {
    width: 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-md-7 {
    width: 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-md-6 {
    width: 50%;
    max-width: 50%;
  }
  .col-md-5 {
    width: 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-md-4 {
    width: 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-md-3 {
    width: 25%;
    max-width: 25%;
  }
  .col-md-2 {
    width: 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-md-1 {
    width: 8.33333333%;
    max-width: 8.33333333%;
  }
}

@media (min-width: #{map.get($grid-breakpoints, lg)}) {
  .col-lg-12 {
    width: 100%;
    max-width: 100%;
  }
  .col-lg-11 {
    width: 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-lg-10 {
    width: 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-lg-9 {
    width: 75%;
    max-width: 75%;
  }
  .col-lg-8 {
    width: 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-lg-7 {
    width: 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-lg-6 {
    width: 50%;
    max-width: 50%;
  }
  .col-lg-5 {
    width: 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-lg-4 {
    width: 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-lg-3 {
    width: 25%;
    max-width: 25%;
  }
  .col-lg-2 {
    width: 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-lg-1 {
    width: 8.33333333%;
    max-width: 8.33333333%;
  }
}

@media (min-width: #{map.get($grid-breakpoints, xl)}) {
  .col-x-12 {
    width: 100%;
    max-width: 100%;
  }
  .col-x-11 {
    width: 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-x-10 {
    width: 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-x-9 {
    width: 75%;
    max-width: 75%;
  }
  .col-x-8 {
    width: 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-x-7 {
    width: 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-x-6 {
    width: 50%;
    max-width: 50%;
  }
  .col-x-5 {
    width: 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-x-4 {
    width: 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-x-3 {
    width: 25%;
    max-width: 25%;
  }
  .col-x-2 {
    width: 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-x-1 {
    width: 8.33333333%;
    max-width: 8.33333333%;
  }
}

@media (min-width: #{map.get($grid-breakpoints, xl)}) {
  .col-xl-12 {
    width: 100%;
    max-width: 100%;
  }
  .col-xl-11 {
    width: 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-xl-10 {
    width: 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-xl-9 {
    width: 75%;
    max-width: 75%;
  }
  .col-xl-8 {
    width: 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-xl-7 {
    width: 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-xl-6 {
    width: 50%;
    max-width: 50%;
  }
  .col-xl-5 {
    width: 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-xl-4 {
    width: 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-xl-3 {
    width: 25%;
    max-width: 25%;
  }
  .col-xl-2 {
    width: 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-xl-1 {
    width: 8.33333333%;
    max-width: 8.33333333%;
  }
}

@media (min-width: #{map.get($grid-breakpoints, xxl)}) {
  .col-xxl-12 {
    width: 100%;
    max-width: 100%;
  }
  .col-xxl-11 {
    width: 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-xxl-10 {
    width: 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-xxl-9 {
    width: 75%;
    max-width: 75%;
  }
  .col-xxl-8 {
    width: 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-xxl-7 {
    width: 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-xxl-6 {
    width: 50%;
    max-width: 50%;
  }
  .col-xxl-5 {
    width: 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-xxl-4 {
    width: 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-xxl-3 {
    width: 25%;
    max-width: 25%;
  }
  .col-xxl-2 {
    width: 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-xxl-1 {
    width: 8.33333333%;
    max-width: 8.33333333%;
  }
}

/********** Grid styles end **********/
