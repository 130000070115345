@use "sass:map";
@use "variables/breakpoints";
@use "variables/gutters";
@use "mixins/breakpoints" as mixins;

.container-wide {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: #{map.get(breakpoints.$breakpoints, xxxl)};
  margin: 0 auto;
}

.container-small {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 100rem;
  padding: 0 1.6rem;
  margin: 0 auto;
}

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: #{map.get(breakpoints.$breakpoints, xl)};
  margin: 0 auto;
  padding: 0 var(--container-gutter);
}

a {
  color: inherit;
}

a {
  --transition: color .2s ease-in-out;
  transition: var(--transition);

  &.underlined {
    text-decoration-line: underline;
    text-decoration-style: solid;
    text-decoration-skip-ink: none;
    text-decoration-thickness: auto;
    text-underline-offset: auto;
    text-underline-position: from-font;
  }

  app-icon {
    transition: background-color .2s ease-in-out;
  }

  &:hover:not(.button) {
    color: var(--link-hover) !important;

  }
}

//app-icon {
//  &:hover {
//    .icon {
//      --icon-color: var(--link-hover) !important;
//    }
//  }
//
//}

.full-block-link {
  &:after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    cursor: pointer;
    z-index: 100;
  }
}

.hover-gradient {
  position: relative;

  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    background: transparent;
    transition: opacity .2s ease-in-out;
  }

  &:hover {
    &:after {
      opacity: 1;
    }
  }

  &__big {
    &:after {
      background: var(--gradient-big);
    }
  }

  &__small {
    &:after {
      background: var(--gradient-small);
    }
  }
}
