@use "variables/breakpoints";

@mixin screen($breakpoint) {
  @if map-has-key(breakpoints.$breakpoints, $breakpoint) {
    @media (max-width: map-get(breakpoints.$breakpoints, $breakpoint) - 1px) {
      @content;
    }
  } @else {
    @warn 'Breakpoint `#{$breakpoint}` not found! Aviable #{map-keys(breakpoints.$breakpoints)}';
  }
}

@mixin min-screen($breakpoint) {
  @if map-has-key(breakpoints.$breakpoints, $breakpoint) {
    @media (min-width: map-get(breakpoints.$breakpoints, $breakpoint) + 1px) {
      @content;
    }
  } @else {
    @warn 'Breakpoint `#{$breakpoint}` not found! Aviable #{map-keys(breakpoints.$breakpoints)}';
  }
}

@mixin hideOn($breakpoint) {
  @include screen($breakpoint) {
    display: none;
  }
}

@mixin showOn($breakpoint) {
  display: none;
  @include screen($breakpoint) {
    display: flex;
  }
}

@mixin fullContainerWidth() {
  width: calc(100% + var(--container-gutter) * 2);
  margin-left: calc(var(--container-gutter) * -1);
  margin-right: calc(var(--container-gutter) * -1);
}

