@font-face {
  font-family: 'Myriad Pro It';
  src: url('/fonts/MyriadPro-It.woff2') format('woff2'),
  url('/fonts/MyriadPro-It.woff') format('woff'),
  url('/fonts/MyriadPro-It.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Myriad Pro Semibold';
  src: url('/fonts/MyriadPro-Semibold.woff2') format('woff2'),
  url('/fonts/MyriadPro-Semibold.woff') format('woff'),
  url('/fonts/MyriadPro-Semibold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Myriad Pro LightIt';
  src: url('/fonts/MyriadPro-LightIt.woff2') format('woff2'),
  url('/fonts/MyriadPro-LightIt.woff') format('woff'),
  url('/fonts/MyriadPro-LightIt.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Myriad Pro Bold';
  src: url('/fonts/MyriadPro-Bold.woff2') format('woff2'),
  url('/fonts/MyriadPro-Bold.woff') format('woff'),
  url('/fonts/MyriadPro-Bold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Myriad Pro Regular';
  src: url('/fonts/MyriadPro-Regular.woff2') format('woff2'),
  url('/fonts/MyriadPro-Regular.woff') format('woff'),
  url('/fonts/MyriadPro-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Myriad Pro Light';
  src: url('/fonts/MyriadPro-Light.woff2') format('woff2'),
  url('/fonts/MyriadPro-Light.woff') format('woff'),
  url('/fonts/MyriadPro-Light.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Myriad Pro BoldIt';
  src: url('/fonts/MyriadPro-BoldIt.woff2') format('woff2'),
  url('/fonts/MyriadPro-BoldIt.woff') format('woff'),
  url('/fonts/MyriadPro-BoldIt.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Myriad Pro SemiboldIt';
  src: url('/fonts/MyriadPro-SemiboldIt.woff2') format('woff2'),
  url('/fonts/MyriadPro-SemiboldIt.woff') format('woff'),
  url('/fonts/MyriadPro-SemiboldIt.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Myriad Pro Black';
  src: url('/fonts/MyriadPro-Black.woff2') format('woff2'),
  url('/fonts/MyriadPro-Black.woff') format('woff'),
  url('/fonts/MyriadPro-Black.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Myriad Pro BlackIt';
  src: url('/fonts/MyriadPro-BlackIt.woff2') format('woff2'),
  url('/fonts/MyriadPro-BlackIt.woff') format('woff'),
  url('/fonts/MyriadPro-BlackIt.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}



@font-face {
  font-family: 'Adjusted Arial';
  size-adjust: 91.92%;
  src: local(Arial);
}

@font-face {
  font-family: 'Adjusted Arial Semibold';
  size-adjust: 96.37%;
  src: local(Arial);
}
